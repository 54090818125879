export class SessionStorageUtils {
    static isSessionStorageSupported(): boolean {
        return (
            window && typeof window.sessionStorage != 'undefined' && window.sessionStorage != null
        );
    }

    static set(key: string, item: string) {
        if (SessionStorageUtils.isSessionStorageSupported())
            window.sessionStorage.setItem(key, item);
    }

    static get(key: string): string {
        return SessionStorageUtils.isSessionStorageSupported()
            ? window.sessionStorage.getItem(key)
            : null;
    }

    static remove(key: string) {
        if (SessionStorageUtils.isSessionStorageSupported()) window.sessionStorage.removeItem(key);
    }

    static clear() {
        if (SessionStorageUtils.isSessionStorageSupported()) window.sessionStorage.clear();
    }
}
